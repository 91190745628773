import React from "react";
import "./home.scss";
import logo from "../../assets/images/logo.png";
import rokuLogo from "../../assets/images/rokuLogo.svg";
import androidTVLogo from "../../assets/images/androidTvLogo.png";
import fireTvLogo from "../../assets/images/fireTvLogo.png";
import video from "../../assets/video/country tv_3rd.mp4";
import Header from "../../Components/SmallScreenHeader";
import Videos from "../Videos";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function Home() {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const token =
    Cookies.get("authToken") && JSON.parse(Cookies.get("authToken"));

  return (
    <div className="home">
      <div className="home__start">
        {open ? (
          <Header
            onClose={() => {
              setOpen(false);
            }}
          />
        ) : (
          <ion-icon
            name="menu-outline"
            size="large"
            onClick={() => setOpen(true)}
          ></ion-icon>
        )}
        <div className="nav_container" style={{ background: "#66268e", position: "absolute", top: "0", zIndex: "9" }}>
          <img src={logo} alt="" />
          <nav className="home__start__menu">
            <span
              className={`link ${location.pathname === "/" && "selected"}`}
              onClick={() => history.push("/")}
            >
              Home
            </span>
            {/* <span
              className={`link ${
                location.pathname === "/videos" && "selected"
              }`}
              onClick={() => history.push("/videos")}
            >
              Videos
            </span> */}
            <span
              className={`link ${location.pathname === "/about" && "selected"}`}
              onClick={() => history.push("/about")}
            >
              About us
            </span>
            {/* <span
              className={`link ${location.pathname === "/productions" && "selected"
                }`}
              onClick={() => history.push("/productions")}
            >
              Productions
            </span> */}
            {/* <span
              className={`link ${
                location.pathname === "/events" && "selected"
              }`}
              onClick={() => history.push("/events")}
            >
              Events
            </span> */}
            <span
              className={`link ${location.pathname === "/contact" && "selected"
                }`}
              onClick={() => history.push("/contact")}
            >
              Contact
            </span>
            {/* {token ? (
              <span
                className={`link `}
                onClick={() => {
                  Cookies.remove("authToken");
                  history.replace("/");
                }}
              >
                Sign Out
              </span>
            ) : (
              <span
                className={`link ${
                  location.pathname === "/signin" && "selected"
                }`}
                onClick={() => history.push("/signin")}
              >
                Sign In
              </span>
            )} */}
          </nav>
        </div>
        {/* <div className="embed-container">
          <iframe title="vimeo-player" src="https://player.vimeo.com/video/795780594?h=67451a88af&autoplay=1&loop=1&title=0&controls=0badge=0&autopause=0&player_id=0&app_id=58479" allow="autoplay; fullscreen" frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
         <iframe src="https://player.vimeo.com/video/**yourvideonumberhere**" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> 
        </div> */}
        <span className="home__start__text">
          We are Live on
          <br />
          <a
            href="https://my.roku.com/account/add?channel=P95NZD"
            target="_blank"
          >
            <img
              src={rokuLogo}
              style={{
                width: "auto",
                height: 50,
                marginLeft: "10px",
                marginTop: 10,
              }}
            />
          </a>
          <br />
          <a
            href="https://play.google.com/store/apps/details?id=com.nctvn.nctvnandroidtvlauncher"
            target="_blank"
          >
            <img
              src={androidTVLogo}
              style={{
                width: "auto",
                height: 40,
                marginLeft: "10px",
                marginTop: 10,
              }}
            />
          </a>
          <br />
          &
          <br />
          <a
            href="https://www.amazon.com/NCTVN-Nashville-Country-Television-Network/dp/B0916H9L3Q/ref=sr_1_1?dchild=1&keywords=nctvn&qid=1617934219&s=mobile-apps&sr=1-1"
            target="_blank"
          >
            <img
              src={fireTvLogo}
              style={{
                width: "auto",
                height: 65,
                marginLeft: "10px",
                marginTop: 10,
              }}
            />
          </a>
        </span>

        {/* <p className="pairText">
          already downloaded our apps &nbsp;
          <button className="pairDevice" onClick={() => history.push("/pair")}>
            Pair Device
          </button>
        </p> */}
        <p className="emailText">
          Report Issues <a href="mailto:vikas@nctvn.com">s.jones@nctvn.com</a>
        </p>
      </div>

      <section className="home__mission">
        <div className="home__mission__container">
          <h4>Our Mission</h4>
          <p>
            The Nashville Country Television Network's mission is to provide a
            platform for producers, artists, musicians, and performers of
            Traditional Country, Gospel, Bluegrass and Americana music, to
            showcase their talents to a worldwide audience.
          </p>
        </div>

        <video autoPlay muted loop id="myVideo">
          <source src={video} type="video/mp4" />
        </video>
      </section>

      <section className="home__network">
        {/* <img src={img2} alt="" /> */}

        <h4>Our Network</h4>
        <div className="home__network__container">
          <div className="home__network__container__texts">
            <span>Purpose</span>
            <p>
              The Nashville Country Television Network is more than just a
              streaming channel.
            </p>
            <p>
              Its a network of producers, artists, musicians, songwriters, and
              performers working together to develop a place to promote the
              products they develop, whether a reality show production, a music
              video, an original song or other interesting products. This
              network will strive to develop ways to provide another source for
              a revenue stream for the independent talent in the Traditional
              Country , Gospel, Bluegrass, and Americana music genres. There is
              a lot of great talent that never gets an opportunity to be seen or
              heard. Our mission is to give them another avenue of opportunity.
            </p>
          </div>
          {/* <button onClick={() => history.push("/about")}>Find out more</button> */}
        </div>
      </section>
      <section className="home__featuredVideos">
        <Videos />
      </section>
      <section className="home__Apps">
        {/* <h4>NCTVN Apps Launching Soon</h4> */}
        <h4 style={{ marginBottom: 25 }}>
          Sign in for free access to our Nashville Country Television Apps on
          Amazon Fire TV, ROKU, and Android TV. You will gain access to what is
          playing, what’s coming and the Nashville Country Spotlight e-magazine.
        </h4>
        <h4 style={{ marginBottom: 50 }}>
          You will be notified as new concerts and new content goes online. The
          network is still building content with many programs in the works.
          Tune in frequently for new developments.
        </h4>
        <div className="home__Apps__container">
          <img src={rokuLogo} style={{ width: "auto", height: 50 }} />
          <img src={fireTvLogo} style={{ width: "auto", height: 65 }} />
          <img src={androidTVLogo} style={{ width: "auto", height: 45 }} />
        </div>
      </section>
    </div>
  );
}

export default Home;
