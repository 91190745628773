import React, { useState, useEffect } from "react";
import "./videos.scss";
// import Header from "../../Components/Header";

function Videos() {
  const [videos, setVideos] = useState([]);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    // if (localStorage.data) {
    //   setVideos(JSON.parse(localStorage.data));
    // } else {
    fetch("https://api.nctvn.com/getCommercialVideo", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        // localStorage.data = JSON.stringify(res);
        setVideos(res);
      });
    // }
  }, []);

  return (
    <>
      {openPlayer && (
        <div className="video_model">
          <div>
            <ion-icon
              name="close-outline"
              size="large"
              onClick={() => setOpenPlayer(false)}
            ></ion-icon>
          </div>
          <video src={videoUrl} autoPlay muted loop controls />
        </div>
      )}

      {/* <Header /> */}
      <div className="videos">
        <h4>Featured Video Clips</h4>
        <div className="videos__content">
          {videos.map((item) => (
            <div
              className="videos__content__container"
              onClick={() => {
                setOpenPlayer(true);
                setVideoUrl(item?.url);
              }}
            >
              <img src={item?.photoUrl} alt="" />
              {/* <div
                style={{ position: "absolute", height: "100%", width: "100%" }}
              > */}
              <p style={{}}>{item?.videoName}</p>
              {/* </div> */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Videos;
