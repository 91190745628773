import React, { useEffect, useState } from "react";
import "./tvLogin.scss";
import logo from "../../assets/images/logo.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

function TvLogin() {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [oldCode, setOldCode] = useState([]);
  const [location, setLocation] = useState("");
  const [selectedDevice, selectDevice] = useState("");
  const [isDeleting, setDelete] = useState(false);

  const token =
    Cookies.get("authToken") && JSON.parse(Cookies.get("authToken"));

  useEffect(() => {
    if (!token) {
      history.push("/signin?from=pair");
    }
  }, [token]);

  const handleClick = () => {
    if (!code) {
      alert("Please add Device code from NCTVN app on TV");
      return;
    }
    setLoading(true);
    setOpen(false);
    const body = JSON.stringify({
      deviceCode: code,
      type: location === "select" || location === "" ? "Living Room" : location,
    });
    if (!token) {
      history.push("/signin?from=pair");
    }
    fetch(`https://api.nctvn.com/users/${token?.user?.id}/device`, {
      method: "POST",
      body,
      headers: {
        Authorization: `${token?.token_type} ${token?.access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res?.status === "FAILURE") {
          if (res.error) {
            alert(res.error);
          } else {
            setOpen(true);
            setOldCode(res.message);
          }
          setLoading(false);
        } else if (res.deviceCode) {
          setLoading(false);
          setMessage("Device registered successfully");
          setCode("");
        } else {
          history.push("/signin?from=pair");
        }
      })
      .catch((e) => {
        console.log(e);

        setLoading(false);
        setMessage("Something went wrong... Please try again!");
      });
  };

  const handleDelete = (code) => {
    // setOpen(false);
    setDelete(true);
    if (!token) {
      history.push("/signin?from=pair");
    }

    fetch(`https://api.nctvn.com/device/${code}`, {
      method: "DELETE",
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        // handleClick();
        setDelete(false);

        let codes = [...oldCode];
        const x = codes.filter((item) => item.deviceCode !== code);
        setOldCode(x);
      })
      .catch((e) => {
        console.log(e);

        setDelete(false);
        setMessage("Something went wrong... Please try again!");
      });
  };

  return (
    <>
      {open && (
        <div className="modal">
          <div className="modal__content">
            <p className="modal__content__text">
              {" "}
              You already have 5 devices registered with this account. Do you
              want to delete any old device and add new device?{" "}
            </p>

            <div className="modal__content__devices">
              {oldCode.map((item) => (
                <div key={item.id} className="modal__content__devices__item">
                  <span className="modal__content__devices__item__device">
                    {item.deviceCode}
                  </span>
                  {isDeleting && selectedDevice === item.deviceCode ? (
                    <div className="loader" />
                  ) : (
                    <button
                      className="modal__content__devices__item__delBtn"
                      onClick={() => {
                        selectDevice(item.deviceCode);
                        handleDelete(item.deviceCode);
                      }}
                    >
                      DELETE
                    </button>
                  )}
                </div>
              ))}
            </div>
            <div className="modal__content__btnContainer">
              <button
                className="modal__content__btnContainer__no"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                className="modal__content__btnContainer__yes"
                onClick={handleClick}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="tvLogin">
        <div className="tvLogin__wrapper">
          <img src={logo} className="tvLogin__wrapper__logo" alt="" />

          <p className="tvLogin__wrapper__txt">
            Enter the code on the NCTVN App on your TV
          </p>

          <input
            type="text"
            className="tvLogin__wrapper__input"
            placeholder="Enter Code here"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            value={code}
            onFocus={() => setMessage("")}
          />

          {/* <p>Where is Your Device?</p> */}

          {/* <input
            type="text"
            className="tvLogin__wrapper__input"
            placeholder="Enter Code here"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            value={code}
            onFocus={() => setMessage("")}
          /> */}

          <select
            name="location"
            className="tvLogin__wrapper__input"
            onChange={(e) => setLocation(e.target.value)}
          >
            <option value="select">Where is Your Device?</option>
            <option value="Living Room">Living Room</option>
            <option value="Bed Room">Bed Room</option>
            <option value="Hall">Hall</option>
            <option value="Work Place">Work Place</option>
            <option value="Office">Office</option>
          </select>

          {isLoading ? (
            <div className="loader" />
          ) : (
            <button className="tvLogin__wrapper__btn" onClick={handleClick}>
              Continue
            </button>
          )}
          {<p className="tvLogin__wrapper__txt2">{message && message}</p>}
        </div>
      </div>
    </>
  );
}

export default TvLogin;
