import React from "react";
import "./SmallScreenHeader.scss";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function SmallScreenHeader({ onClose }) {
  const history = useHistory();
  const location = useLocation();
  const token =
    Cookies.get("authToken") && JSON.parse(Cookies.get("authToken"));

  return (
    <div className="SmallScreenHeader">
      <div>
        <ion-icon
          name="close-outline"
          size="large"
          onClick={onClose}
        ></ion-icon>
      </div>
      <nav>
        <span
          className={`link ${location.pathname === "/" && "selected"}`}
          onClick={() => history.push("/")}
        >
          Home
        </span>
        {/* <span
          className={`link ${location.pathname === "/videos" && "selected"}`}
          onClick={() => history.push("/videos")}
        >
          Videos
        </span> */}
        <span
          className={`link ${location.pathname === "/about" && "selected"}`}
          onClick={() => history.push("/about")}
        >
          About us
        </span>
        <span
          className={`link ${
            location.pathname === "/productions" && "selected"
          }`}
          onClick={() => history.push("/productions")}
        >
          Productions
        </span>
        {/* <span
          className={`link ${location.pathname === "/events" && "selected"}`}
          onClick={() => history.push("/events")}
        >
          Events
        </span> */}
        <span
          className={`link ${location.pathname === "/contact" && "selected"}`}
          onClick={() => history.push("/contact")}
        >
          Contact
        </span>
        {/* {token ? (
          <span
            className={`link `}
            onClick={() => {
              Cookies.remove("authToken");
              history.replace("/");
            }}
          >
            Sign Out
          </span>
        ) : (
          <span
            className={`link ${location.pathname === "/signin" && "selected"}`}
            onClick={() => history.push("/signin")}
          >
            Sign In
          </span>
        )} */}
      </nav>
    </div>
  );
}

export default SmallScreenHeader;
