import React, { useState, useCallback } from "react";
import "./signin.scss";
import Header from "../../Components/Header";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function SignIn() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    email: "",
    userSecret: "",
    grant_type: "password",
  });

  const handleInputChange = useCallback((e) =>
    setFormState({ ...formState, [e.target.name]: e.target.value })
  );

  const handleLoginSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formState.email.length === 0 || formState.userSecret.length === 0) {
      alert("You must enter an email and password.");
      setLoading(false);
      return;
    }

    var formBody = [];
    for (var property in formState) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(formState[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch("https://api.nctvn.com/oauth/token", {
      method: "POST",
      body: formBody,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        setLoading(false);
        if (res.access_token) {
          Cookies.set("authToken", JSON.stringify(res), {
            expires: 1,
          });
          if (location.search.includes("pair")) history.push("/pair");
          else history.push("/events");
        } else {
          alert("Invalid Email or Password");
        }
      })
      .catch((err) => {
        console.log("err", err);
        alert("Something went wrong....Please try again");
        setLoading(false);
      });
  });

  return (
    <div className="signin">
      <Header />
      <div className="signin_wrapper">
        <h1 className="signin_wrapper_header"> SIGN IN</h1>
        <div className="wrapper">
          <form onSubmit={handleLoginSubmit}>
            <div className="signin_wrapper_inputWrapper">
              <input
                className="signin_wrapper_inputWrapper_input"
                name="email"
                type="email"
                placeholder="Email"
                onChange={handleInputChange}
              />
              <input
                className="signin_wrapper_inputWrapper_input"
                name="userSecret"
                type="password"
                placeholder="Password"
                onChange={handleInputChange}
              />
            </div>
            <div className="signin_wrapper_buttonWrapper">
              {isLoading ? (
                <div className="loader" />
              ) : (
                <>
                  <button
                    className="signin_wrapper_buttonWrapper_button"
                    type="submit"
                  >
                    Sign In
                  </button>
                  <p style={{ color: "white", fontSize: "1rem" }}>
                    New User?{" "}
                    <span
                      onClick={() => {
                        if (location.search.includes("pair"))
                          history.push("/signUp?from=pair");
                        else history.push("/signUp");
                      }}
                      style={{
                        cursor: "pointer",
                        fontSize: "1rem",
                        textDecoration: "underline",
                        color: "rgb(241, 18, 134)",
                      }}
                    >
                      Register here
                    </span>
                  </p>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
