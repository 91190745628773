import React from "react";
import "./policy.scss";

function PrivacyPolicy({ onClose }) {
  return (
    <div className="policy">
      <div>
        <ion-icon
          name="close-outline"
          size="large"
          onClick={onClose}
          style={{
            color: "black",
            display: "block",
            position: "relative",
            float: "right",
          }}
        ></ion-icon>
      </div>
      <div className="WordSection1">
        <p className="MsoNormal">
          <h1 lang="EN-US">Privacy Policy</h1>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">Last updated: February 19, 2021</span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </span>
        </p>
        <div
          style={{
            border: "none",
            borderBottom: "solid #4F81BD 1.0pt",
            padding: "0in 0in 4.0pt 0in",
          }}
        >
          <p className="MsoTitle">
            <span lang="EN-US">Interpretation and Definitions</span>
          </p>
        </div>
        <h2>
          <span lang="EN-US">Interpretation</span>
        </h2>
        <p className="MsoNormal">
          <span lang="EN-US">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </span>
        </p>
        <h2>
          <span lang="EN-US">Definitions</span>
        </h2>
        <p className="MsoNormal">
          <span lang="EN-US">For the purposes of this Privacy Policy:</span>
        </p>
        <p className="MsoListParagraphCxSpFirst">
          <b>
            <span lang="EN-US">Account</span>
          </b>
          <span lang="EN-US">
            {" "}
            means a unique account created for You to access our Service or
            parts of our Service.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Company</span>
          </b>
          <span lang="EN-US">
            {" "}
            (referred to as either "the Company", "We", "Us" or "Our" in this
            Agreement) refers to Jones Boys Enterprises LLC; DBA Nashville
            Country Television Network, 2416 Music Valley Drive, suite 137,
            Nashville Tennessee 37214 .
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Cookies</span>
          </b>
          <span lang="EN-US">
            {" "}
            are small files that are placed on Your computer, mobile device or
            any other device by a website, containing the details of Your
            browsing history on that website among its many uses.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Country</span>
          </b>
          <span lang="EN-US"> refers to: Tennessee, United States</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Device</span>
          </b>
          <span lang="EN-US">
            {" "}
            means any device that can access the Service such as a computer, a
            cellphone or a digital tablet.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Personal Data</span>
          </b>
          <span lang="EN-US">
            {" "}
            is any information that relates to an identified or identifiable
            individual.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Service</span>
          </b>
          <span lang="EN-US"> refers to the Website.</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Service Provider</span>
          </b>
          <span lang="EN-US">
            {" "}
            means any natural or legal person who processes the data on behalf
            of the Company. It refers to third-party companies or individuals
            employed by the Company to facilitate the Service, to provide the
            Service on behalf of the Company, to perform services related to the
            Service or to assist the Company in analyzing how the Service is
            used.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Third-party Social Media Service</span>
          </b>
          <span lang="EN-US">
            {" "}
            refers to any website or any social network website through which a
            User can log in or create an account to use the Service.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Usage Data</span>
          </b>
          <span lang="EN-US">
            {" "}
            refers to data collected automatically, either generated by the use
            of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Website</span>
          </b>
          <span lang="EN-US">
            {" "}
            refers to Nashville Country Television Network, accessible from{" "}
            <a href="http://www.nctvn.com/">
              <span style={{ color: "windowtext", textDecoration: "none" }}>
                http://www.nctvn.com
              </span>
            </a>
          </span>
        </p>
        <p className="MsoListParagraphCxSpLast">
          <b>
            <span lang="EN-US">You</span>
          </b>
          <span lang="EN-US">
            {" "}
            means the individual accessing or using the Service, or the company,
            or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </span>
        </p>
        <div
          style={{
            border: "none",
            borderBottom: "solid #4F81BD 1.0pt",
            padding: "0in 0in 4.0pt 0in",
          }}
        >
          <p className="MsoTitle">
            <span lang="EN-US">Collecting and Using Your Personal Data</span>
          </p>
        </div>
        <h2>
          <span lang="EN-US">Types of Data Collected</span>
        </h2>
        <h3>
          <span lang="EN-US">Personal Data</span>
        </h3>
        <p className="MsoNormal">
          <span lang="EN-US">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </span>
        </p>
        <p className="MsoListParagraphCxSpFirst">
          <span lang="EN-US">Email address</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">First name and last name</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">Phone number</span>
        </p>
        <p className="MsoListParagraphCxSpLast">
          <span lang="EN-US">Usage Data</span>
        </p>
        <h3>
          <span lang="EN-US">Usage Data</span>
        </h3>
        <p className="MsoNormal">
          <span lang="EN-US">
            Usage Data is collected automatically when using the Service.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </span>
        </p>
        <h3>
          <span lang="EN-US">
            Information from Third-Party Social Media Services
          </span>
        </h3>
        <p className="MsoNormal">
          <span lang="EN-US">
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Services:
          </span>
        </p>
        <p className="MsoListBulletCxSpFirst">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">Google</span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">Facebook</span>
        </p>
        <p className="MsoListBulletCxSpLast">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">Twitter</span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media Service's
            account, such as Your name, Your email address, Your activities or
            Your contact list associated with that account.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service's account.
            If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
          </span>
        </p>
        <h3>
          <span lang="EN-US">Tracking Technologies and Cookies</span>
        </h3>
        <p className="MsoNormal">
          <span lang="EN-US">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </span>
        </p>
        <p className="MsoListBulletCxSpFirst">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">Cookies or Browser Cookies.</span>
          </b>
          <span lang="EN-US">
            {" "}
            A cookie is a small file placed on Your Device. You can instruct
            Your browser to refuse all Cookies or to indicate when a Cookie is
            being sent. However, if You do not accept Cookies, You may not be
            able to use some parts of our Service. Unless you have adjusted Your
            browser setting so that it will refuse Cookies, our Service may use
            Cookies.
          </span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">Flash Cookies.</span>
          </b>
          <span lang="EN-US">
            Certain features of our Service may use local stored objects (or
            Flash Cookies) to collect and store information about Your
            preferences or Your activity on our Service. Flash Cookies are not
            managed by the same browser settings as those used for Browser
            Cookies. For more information on how You can delete Flash Cookies,
            please read "Where can I change the settings for disabling, or
            deleting local shared objects?" available at{" "}
            <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">
              <span style={{ color: "windowtext", textDecoration: "none" }}>
                https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
              </span>
            </a>
          </span>
        </p>
        <p className="MsoListBulletCxSpLast">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">Web Beacons.</span>
          </b>
          <span lang="EN-US">
            Certain sections of our Service and our emails may contain small
            electronic files known as web beacons (also referred to as clear
            gifs, pixel tags, and single-pixel gifs) that permit the Company,
            for example, to count users who have visited those pages or opened
            an email and for other related website statistics (for example,
            recording the popularity of a certain section and verifying system
            and server integrity).
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. Learn more about cookies:{" "}
            <a href="https://www.freeprivacypolicy.com/blog/cookies/">
              <span style={{ color: "windowtext", textDecoration: "none" }}>
                Cookies: What Do They Do?
              </span>
            </a>
            .
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </span>
        </p>
        <p className="MsoListParagraphCxSpFirst">
          <b>
            <span lang="EN-US">Necessary / Essential Cookies</span>
          </b>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">Type: Session Cookies</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">Administered by: Us</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Cookies Policy / Notice Acceptance Cookies</span>
          </b>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">Type: Persistent Cookies</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">Administered by: Us</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">Functionality Cookies</span>
          </b>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">Type: Persistent Cookies</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">Administered by: Us</span>
        </p>
        <p className="MsoListParagraphCxSpLast">
          <span lang="EN-US">
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </span>
        </p>
        <h2>
          <span lang="EN-US">Use of Your Personal Data</span>
        </h2>
        <p className="MsoNormal">
          <span lang="EN-US">
            The Company may use Personal Data for the following purposes:
          </span>
        </p>
        <p className="MsoListParagraphCxSpFirst">
          <b>
            <span lang="EN-US">To provide and maintain our Service</span>
          </b>
          <span lang="EN-US">
            , including to monitor the usage of our Service.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">To manage Your Account:</span>
          </b>
          <span lang="EN-US">
            {" "}
            to manage Your registration as a user of the Service. The Personal
            Data You provide can give You access to different functionalities of
            the Service that are available to You as a registered user.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">For the performance of a contract:</span>
          </b>
          <span lang="EN-US">
            {" "}
            the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">To contact You:</span>
          </b>
          <span lang="EN-US">
            {" "}
            To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile application's
            push notifications regarding updates or informative communications
            related to the functionalities, products or contracted services,
            including the security updates, when necessary or reasonable for
            their implementation.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">To provide You</span>
          </b>
          <span lang="EN-US">
            {" "}
            with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that
            you have already purchased or enquired about unless You have opted
            not to receive such information.
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">To manage Your requests:</span>
          </b>
          <span lang="EN-US"> To attend and manage Your requests to Us.</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">For business transfers:</span>
          </b>
          <span lang="EN-US">
            {" "}
            We may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred.
          </span>
        </p>
        <p className="MsoListParagraphCxSpLast">
          <b>
            <span lang="EN-US">For other purposes</span>
          </b>
          <span lang="EN-US">
            : We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            We may share Your personal information in the following situations:
          </span>
        </p>
        <p className="MsoListBulletCxSpFirst">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">With Service Providers:</span>
          </b>
          <span lang="EN-US">
            {" "}
            We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, for payment processing,
            to contact You.
          </span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">For business transfers:</span>
          </b>
          <span lang="EN-US">
            {" "}
            We may share or transfer Your personal information in connection
            with, or during negotiations of, any merger, sale of Company assets,
            financing, or acquisition of all or a portion of Our business to
            another company.
          </span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">With Affiliates:</span>
          </b>
          <span lang="EN-US">
            We may share Your information with Our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us.
          </span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">With business partners:</span>
          </b>
          <span lang="EN-US">
            {" "}
            We may share Your information with Our business partners to offer
            You certain products, services or promotions.
          </span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">With other users:</span>
          </b>
          <span lang="EN-US">
            when You share personal information or otherwise interact in the
            public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside. If You interact with
            other users or register through a Third-Party Social Media Service,
            Your contacts on the Third-Party Social Media Service may see Your
            name, profile, pictures and description of Your activity. Similarly,
            other users will be able to view descriptions of Your activity,
            communicate with You and view Your profile.
          </span>
        </p>
        <p className="MsoListBulletCxSpLast">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN-US">With Your consent</span>
          </b>
          <span lang="EN-US">
            : We may disclose Your personal information for any other purpose
            with Your consent.
          </span>
        </p>
        <h2>
          <span lang="EN-US">Retention of Your Personal Data</span>
        </h2>
        <p className="MsoNormal">
          <span lang="EN-US">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </span>
        </p>
        <h2>
          <span lang="EN-US">Transfer of Your Personal Data</span>
        </h2>
        <p className="MsoNormal">
          <span lang="EN-US">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to � and maintained on � computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </span>
        </p>
        <h2>
          <span lang="EN-US">Disclosure of Your Personal Data</span>
        </h2>
        <h3>
          <span lang="EN-US">Business Transactions</span>
        </h3>
        <p className="MsoNormal">
          <span lang="EN-US">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </span>
        </p>
        <h3>
          <span lang="EN-US">Law enforcement</span>
        </h3>
        <p className="MsoNormal">
          <span lang="EN-US">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </span>
        </p>
        <h3>
          <span lang="EN-US">Other legal requirements</span>
        </h3>
        <p className="MsoNormal">
          <span lang="EN-US">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </span>
        </p>
        <p className="MsoListBulletCxSpFirst">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">Comply with a legal obligation</span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">
            Protect and defend the rights or property of the Company
          </span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </span>
        </p>
        <p className="MsoListBulletCxSpMiddle">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">
            Protect the personal safety of Users of the Service or the public
          </span>
        </p>
        <p className="MsoListBulletCxSpLast">
          <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
            �
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">Protect against legal liability</span>
        </p>
        <h2>
          <span lang="EN-US">Security of Your Personal Data</span>
        </h2>
        <p className="MsoNormal">
          <span lang="EN-US">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </span>
        </p>
        <div
          style={{
            border: "none",
            borderBottom: "solid #4F81BD 1.0pt",
            padding: "0in 0in 4.0pt 0in",
          }}
        >
          <p className="MsoTitle">
            <span lang="EN-US">
              Detailed Information on the Processing of Your Personal Data
            </span>
          </p>
        </div>
        <p className="MsoNormal">
          <span lang="EN-US">
            The Service Providers We use may have access to Your Personal Data.
            These third-party vendors collect, store, use, process and transfer
            information about Your activity on Our Service in accordance with
            their Privacy Policies.
          </span>
        </p>
        <h2>
          <span lang="EN-US">Payments</span>
        </h2>
        <p className="MsoNormal">
          <span lang="EN-US">
            We may provide paid products and/or services within the Service. In
            that case, we may use third-party services for payment processing
            (e.g. payment processors).
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            We will not store or collect Your payment card details. That
            information is provided directly to Our third-party payment
            processors whose use of Your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </span>
        </p>
        <p className="MsoListParagraphCxSpFirst">
          <b>
            <span lang="EN-US">Stripe</span>
          </b>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">
            Their Privacy Policy can be viewed at{" "}
            <a href="https://stripe.com/us/privacy">
              <span style={{ color: "windowtext", textDecoration: "none" }}>
                https://stripe.com/us/privacy
              </span>
            </a>
          </span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <b>
            <span lang="EN-US">PayPal</span>
          </b>
        </p>
        <p className="MsoListParagraphCxSpLast">
          <span lang="EN-US">
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
              <span style={{ color: "windowtext", textDecoration: "none" }}>
                https://www.paypal.com/webapps/mpp/ua/privacy-full
              </span>
            </a>
          </span>
        </p>
        <div
          style={{
            border: "none",
            borderBottom: "solid #4F81BD 1.0pt",
            padding: "0in 0in 4.0pt 0in",
          }}
        >
          <p className="MsoTitle">
            <span lang="EN-US">Children's Privacy</span>
          </p>
        </div>
        <p className="MsoNormal">
          <span lang="EN-US">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </span>
        </p>
        <div
          style={{
            border: "none",
            borderBottom: "solid #4F81BD 1.0pt",
            padding: "0in 0in 4.0pt 0in",
          }}
        >
          <p className="MsoTitle">
            <span lang="EN-US">Links to Other Websites</span>
          </p>
        </div>
        <p className="MsoNormal">
          <span lang="EN-US">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </span>
        </p>
        <div
          style={{
            border: "none",
            borderBottom: "solid #4F81BD 1.0pt",
            padding: "0in 0in 4.0pt 0in",
          }}
        >
          <p className="MsoTitle">
            <span lang="EN-US">Changes to this Privacy Policy</span>
          </p>
        </div>
        <p className="MsoNormal">
          <span lang="EN-US">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </span>
        </p>
        <div
          style={{
            border: "none",
            borderBottom: "solid #4F81BD 1.0pt",
            padding: "0in 0in 4.0pt 0in",
          }}
        >
          <p className="MsoTitle">
            <span lang="EN-US">Contact Us</span>
          </p>
        </div>
        <p className="MsoNormal">
          <span lang="EN-US">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </span>
        </p>
        <p className="MsoListParagraphCxSpFirst">
          <span lang="EN-US">By email: info@nctvn.com</span>
        </p>
        <p className="MsoListParagraphCxSpMiddle">
          <span lang="EN-US">
            By visiting this page on our website:{" "}
            <a href="http://nctvn.com/">
              <span style={{ color: "windowtext", textDecoration: "none" }}>
                http://nctvn.com
              </span>
            </a>
          </span>
        </p>
        <p className="MsoListParagraphCxSpLast">
          <span lang="EN-US">
            By mail: 2416 Music Valley Drive, Suite 137 , Nashville Tennessee
            37214
          </span>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
