import React from "react";
import "./Footer.scss";
// import facebook_logo from "../../assets/images/facebook_logo.webp";
// import instagram_logo from "../../assets/images/instagram_logo.jpg";
// import twitter_logo from "../../assets/images/twitter_logo.jpg";

function Footer({ onOpenTerms, onOpenPrivacy }) {
  return (
    <footer className="footer">
      <div className="footer__logos">
        <a
          href="https://www.facebook.com/NCTVNet/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon name="logo-facebook" size={"large"}></ion-icon>
        </a>
        <a
          href="https://twitter.com/nctv_net"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon name="logo-twitter" size={"large"}></ion-icon>
        </a>
        <a
          href="https://www.instagram.com/nashville_country_television/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon name="logo-instagram" size={"large"}></ion-icon>
        </a>
      </div>

      <div className="footer__policy">
        <p onClick={onOpenTerms}>Terms of Service</p>
        <span style={{ margin: "0 10px" }}>-</span>
        <p onClick={onOpenPrivacy}>Privacy Policy</p>
      </div>

      <p>
        Copyright © 2021 Nashville Country Television Network - All Rights
        Reserved.
      </p>
    </footer>
  );
}

export default Footer;
