import "./App.scss";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import About from "./Pages/About";
import Productions from "./Pages/Productions";
import Contact from "./Pages/Contact";
import Videos from "./Pages/Videos";
import Events from "./Pages/Events";
import TvLogin from "./Pages/TvLogin";
import Layout from "./Components/Layout";
import PrivactPolicy from "./Components/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/productions">
              <Productions />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/videos">
              <Videos />
            </Route>
            <Route exact path="/signIn">
              <SignIn />
            </Route>
            <Route exact path="/signUp">
              <SignUp />
            </Route>
            <Route exact path="/events">
              <Events />
            </Route>
            <Route exact path="/pair">
              <TvLogin />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivactPolicy />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
