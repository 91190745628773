import React, { useEffect } from "react";
import PrivacyPolicy from "../PrivacyPolicy";
import Terms from "../Terms";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
  const [openPrivacy, setPrivacy] = React.useState(false);
  const [openTerms, setTerms] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    fetch("https://api.nctvn.com/getCommercialVideo", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.data = JSON.stringify(res);
      });
  }, []);

  const toggleOpenPrivacy = () => {
    setPrivacy(!openPrivacy);
  };
  const toggleOpenTerms = () => {
    setTerms(!openTerms);
  };

  return (
    <div>
      {openPrivacy && <PrivacyPolicy onClose={toggleOpenPrivacy} />}
      {openTerms && <Terms onClose={toggleOpenTerms} />}
      {children}
      {location.pathname !== "/pair" && (
        <Footer
          onOpenTerms={toggleOpenTerms}
          onOpenPrivacy={toggleOpenPrivacy}
        />
      )}
    </div>
  );
}

export default Layout;
