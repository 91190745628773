import React from "react";
import "./About.scss";
import Header from "../../Components/Header";
import img3 from "../../assets/images/img3.jfif";
import img4 from "../../assets/images/img4.JPG";
import img5 from "../../assets/images/img5.JPG";

function About() {
  return (
    <div className="about">
      <Header />

      <div className="about__container">
        <img src={img3} alt="" />

        <div className="about__text">
          <h4>Cumberland River Nashville Tennessee</h4>
          <p className="about__text__heading">
            Nashville Country Television Network
          </p>
          <p>
            Nashville Country Television Network streams Traditional Country,
            Gospel, Bluegrass, and Americana videos in both live stream and on
            demand performances. NCTVN also provides programming for your
            viewing entertainment. See a few of our productions in the works
            below.
          </p>
        </div>
      </div>

      <div
        className="about__container"
        style={{ flexDirection: "row-reverse" }}
      >
        <img src={img4} alt="" />

        <div className="about__text">
          <h4>Bridgestone Arena Nashville Tennessee</h4>
          <p className="about__text__heading">Television Programming</p>
          <p>
            Coming soon are some great television programs, shot live in the
            Nashville area.
          </p>
          <p>
            Some of these programs will provide the inside scoop of life behind
            the scenes for artists, producers, musicians and songwriters.
          </p>
          <p>Other programs will showcase a variety of entertainment.</p>
          <p>
            NCTN will also be providing opportunities for the great talent in
            Nashville and around the world to present their videos to a
            worldwide audience. Video production services are also provided by
            the network.
          </p>
        </div>
      </div>

      <div className="about__container">
        <img src={img5} alt="" />

        <div className="about__text">
          <div className="about__text__heading">
            <h4>Upcoming Opportunities</h4>

            <p>
              Nashville Country Television Network is accepting videos at{" "}
              <a
                href="mailto:submissions@nashvillecountryvideo.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                submissions@nashvillecountryvideo.com{" "}
              </a>
              for possible distribution on the new Nashville Country Television
              Network. Go to{" "}
              <a
                href="https://nashvillecountryvideo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.nashvillecountryvideo.com
              </a>{" "}
              for details and video criteria.
            </p>
          </div>
        </div>

        {/* <button onClick={() => history.push("/productions")}>
          Find out more
        </button> */}
      </div>
    </div>
  );
}

export default About;
