import React, { useState, useCallback, useEffect } from "react";
import Header from "../../Components/Header";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function SignUp() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    email: "",
    userSecret: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobile: "",
  });

  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters

  const handleInputChange = useCallback((e) =>
    setFormState({ ...formState, [e.target.name]: e.target.value })
  );

  useEffect(() => {
    // has uppercase letter
    if (formState.userSecret.toLowerCase() !== formState.userSecret)
      setContainsUL(true);
    else setContainsUL(false);
    // has lowercase letter
    if (formState.userSecret.toUpperCase() !== formState.userSecret)
      setContainsLL(true);
    else setContainsLL(false);
    // has number
    if (/\d/.test(formState.userSecret)) setContainsN(true);
    else setContainsN(false);
    // has special character
    if (/[~`@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(formState.userSecret))
      setContainsSC(true);
    else setContainsSC(false);
    // has 8 characters
    if (formState.userSecret.length >= 8) setContains8C(true);
    else setContains8C(false);
  }, [formState.userSecret]);

  const handleLoginSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    const validate = [];

    if (formState.email.length === 0) {
      validate.push("Email");
    }

    if (formState.userSecret.length === 0) {
      validate.push("Password");
    }

    if (formState.firstName.length === 0) {
      validate.push("FirstName");
    }

    if (formState.confirmPassword.length === 0) {
      validate.push("ConfirmPassword");
    }

    if (formState.lastName.length === 0) {
      validate.push("LastName");
    }

    if (formState.mobile.length === 0) {
      validate.push("Mobile");
    }

    if (validate.length > 0) {
      alert(`You must add ${validate.join(", ")}`);
      setLoading(false);
      return;
    }

    var formBody = [];
    for (var property in formState) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(formState[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch("https://api.nctvn.com/login", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        setLoading(false);
        if (res.access_token) {
          Cookies.set("authToken", JSON.stringify(res), {
            expires: 1,
          });
          if (location.search.includes("pair")) history.push("/pair");
          else history.push("/events");
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        alert("Something went wrong....Please try again");
        setLoading(false);
      });
  });

  const checkConfirmPwd = () => {
    if (formState.confirmPassword !== formState.userSecret) {
      alert("Password and Confirm Password must match.");
      setLoading(false);
      return;
    }
  };

  return (
    <div className="signin">
      <Header />
      <div className="signin_wrapper">
        <h1 className="signin_wrapper_header"> SIGN UP</h1>
        <div className="wrapper">
          <form onSubmit={handleLoginSubmit} autoComplete="off">
            <div className="signin_wrapper_inputWrapper">
              <input
                className="signin_wrapper_inputWrapper_input"
                name="email"
                type="email"
                placeholder="Email"
                onChange={handleInputChange}
              />
              <input
                className="signin_wrapper_inputWrapper_input"
                name="firstName"
                type="text"
                placeholder="First Name"
                onChange={handleInputChange}
              />
              <input
                className="signin_wrapper_inputWrapper_input"
                name="middleName"
                type="text"
                placeholder="Middle Name"
                onChange={handleInputChange}
              />
              <input
                className="signin_wrapper_inputWrapper_input"
                name="lastName"
                type="text"
                placeholder="Last Name"
                onChange={handleInputChange}
              />
              <input
                className="signin_wrapper_inputWrapper_input"
                name="mobile"
                type="number"
                placeholder="Mobile"
                onChange={handleInputChange}
              />
              <input
                className="signin_wrapper_inputWrapper_input"
                name="userSecret"
                type="password"
                placeholder="Password"
                onChange={handleInputChange}
              />
              <div>
                <span
                  weight="bold"
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  Password must contain atleast
                </span>
                <br />
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: formState.userSecret
                      ? contains8C
                        ? "green"
                        : "red"
                      : "#888",
                  }}
                >
                  8 characters,
                </span>

                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: formState.userSecret
                      ? containsUL
                        ? "green"
                        : "red"
                      : "#888",
                  }}
                >
                  1 Upper case letter,
                </span>

                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: formState.userSecret
                      ? containsLL
                        ? "green"
                        : "red"
                      : "#888",
                  }}
                >
                  1 Lower case letter,
                </span>

                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: formState.userSecret
                      ? containsN
                        ? "green"
                        : "red"
                      : "#888",
                  }}
                >
                  1 number, and
                </span>

                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: formState.userSecret
                      ? containsSC
                        ? "green"
                        : "red"
                      : "#888",
                  }}
                >
                  1 Special Character
                </span>
              </div>
              <input
                className="signin_wrapper_inputWrapper_input"
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                onChange={handleInputChange}
                onBlur={checkConfirmPwd}
              />
            </div>
            <div className="signin_wrapper_buttonWrapper">
              {isLoading ? (
                <div className="loader" />
              ) : (
                <button
                  className="signin_wrapper_buttonWrapper_button"
                  type="submit"
                  disabled={
                    !contains8C ||
                    !containsUL ||
                    !containsN ||
                    !containsLL ||
                    !containsSC
                  }
                  style={{
                    background:
                      (!contains8C ||
                        !containsUL ||
                        !containsN ||
                        !containsLL ||
                        !containsSC) &&
                      "#888",
                  }}
                >
                  Sign Up
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
