import React, { useState, useEffect } from "react";
import "./tickets.scss";
import logo from "../../assets/images/logo.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

function Tickets() {
  const [videos, setVideos] = useState([]);
  const history = useHistory();
  const token =
    Cookies.get("authToken") && JSON.parse(Cookies.get("authToken"));

  useEffect(() => {
    if (localStorage.data) {
      setVideos(JSON.parse(localStorage.data));
    } else {
      fetch("https://api.nctvn.com/getCommercialVideo", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => {
          localStorage.data = JSON.stringify(res);
          setVideos(res);
        });
    }
  }, []);

  const handleSignout = () => {
    Cookies.remove("authToken");
    history.push("/");
  };

  const handleClick = () => {
    if (!token) {
      history.push("/signin");
    }
  };

  return (
    <div className="tickets">
      <header className="tickets__header">
        <img src={logo} alt="" className="tickets__header__logo" />
        {token ? (
          <div style={{ display: "flex", margin: 20, alignItems: "center" }}>
            <span style={{ color: "white", marginRight: 30, fontSize: 18 }}>
              Welcome,{" "}
              {`${token?.user?.firstName} ${token?.user?.middleName} ${token?.user?.lastName}`}
            </span>
            <button
              className="tickets__header__signout"
              onClick={() => {
                history.push("/");
              }}
              style={{ margin: 20 }}
            >
              Home
            </button>
            <button
              className="tickets__header__signout"
              onClick={handleSignout}
            >
              Sign Out
            </button>
          </div>
        ) : (
          <div style={{ display: "flex", margin: 20, alignItems: "center" }}>
            <button
              className="tickets__header__signout"
              onClick={() => {
                history.push("/");
              }}
              style={{ margin: 20 }}
            >
              Home
            </button>
            <button
              className="tickets__header__signout"
              onClick={() => {
                history.push("/signIn");
              }}
              style={{ margin: 20 }}
            >
              Sign In
            </button>
          </div>
        )}
      </header>

      <div className="tickets__container">
        <h5 className="tickets__container__header">
          Music Concerts Coming Soon
        </h5>

        <div className="tickets__container__posters">
          {videos.map((item) => (
            <div
              key={item?.videoName}
              className="tickets__container__posters__item"
              onClick={handleClick}
            >
              <img src={item?.photoUrl} alt="" />

              <p>{item?.videoName}</p>
              <span>$xxx</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tickets;
